import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
// @material-ui/core components
import { makeStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from '@mui/material/Snackbar';

// core components
import CustomHeader from "./Header/CustomHeader.js";
import CustomHeaderLinks from "./Header/CustomHeaderLinks";
import Footer from "../components/Footer/Footer.js";
import Parallax from "../components/Parallax/Parallax";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import Button from "./CustomButtons/Button";

// Utils functions
import { checkMobileAndLandscape } from "../utils/Functions";

// Sections for this page
import MainDescriptionSection from "./Sections/MainDescriptionSection.js";
import ContactSection from "./Sections/ContactSection.js";

import styles from "../assets/jss/material-kit-react/views/landingPage.js";

const useStyles = makeStyles(styles);

export default function ContactPage(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const { ...rest } = props;
  const isMobileAndLandscape = checkMobileAndLandscape();

  const openSnackbar = () => {
    setOpen(true);
  };

  const closeSnackbar = () => {
    setOpen(false);
  };

  const setInfoMessage = (message) => {
    setMessage(message);
    openSnackbar();
  };

  return (
    <>
      <CustomHeader
        color="transparent"
        rightLinks={<CustomHeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: isMobileAndLandscape ? 10 : 50,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter transforming image={require("../assets/img/places/PuenteAvellanas.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>{t('contactPage.title')}</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <MainDescriptionSection
            description={
              <div>
                <p>
                  {t('contactPage.contactMe')}
                  <br/>
                  {t('contactPage.moreInforation')}
                </p>

                <Tooltip
                  title={t('socialMedia.youtube')}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    href="https://www.youtube.com/channel/UCABGpIa-50DznAR4BPrIBwQ"
                    target="_blank"
                    color="transparent"
                    className={classes.navLink}
                  >
                    <i className={classes.socialIcons + " fa-brands fa-youtube"} style={{ "color": "#ff0000" }} />
                  </Button>
                </Tooltip>

                <Tooltip
                  title={t('socialMedia.instagram')}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    href="https://www.instagram.com/josuecastrovilla/"
                    target="_blank"
                    color="transparent"
                    className={classes.navLink}
                  >
                    <i className={classes.socialIcons + " fab fa-instagram"} style={{ "color": "#da2e7d" }} />
                  </Button>
                </Tooltip>

                <Tooltip
                  title={t('socialMedia.tiktok')}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    href="https://www.tiktok.com/@josuecastrov11"
                    target="_blank"
                    color="transparent"
                    className={classes.navLink}
                  >
                    <i className={classes.socialIcons + " fab fa-tiktok"} style={{ "color": "#000" }} />
                  </Button>
                </Tooltip>

                <Tooltip
                  title={t('socialMedia.facebook')}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    href="https://www.facebook.com/josuecastrovillalobos/"
                    target="_blank"
                    color="transparent"
                    className={classes.navLink}
                  >
                    <i className={classes.socialIcons + " fab fa-facebook"} style={{ "color": "#1197f5" }} />
                  </Button>
                </Tooltip>
              </div>
            }
          />

          <ContactSection
            fontClass={classes.contactFont}
            setInfoMessage={setInfoMessage}
            t={t}
          />
        </div>
      </div>
      <Parallax filter uping image={require("../assets/img/places/BahiaBallenaUvita2.jpg")} height="20.8vh" style={{ top: "-50px" }}>
        <div className={classes.container}>
          <Footer />
        </div>
      </Parallax>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        message={message}
      />
    </>
  );
}
