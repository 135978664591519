import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import Button from "../CustomButtons/Button";
import LanguageToggle from "../Sections/LanguageToolbar";

import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle";

const useStyles = makeStyles(styles);

export default function CustomHeaderLinks(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link to="/" className={classes.navLink}>
          {t('navBar.mainPage')}
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/portfolio" className={classes.navLink}>
          {t('navBar.portfolio')}
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/information" className={classes.navLink}>
          {t('navBar.information')}
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/contact" className={classes.navLink}>
          {t('navBar.contact')}
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <LanguageToggle />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="youtube-tooltip"
          title={t('navBar.tooltipFollowMeYouTube')}
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://www.youtube.com/@JosueCastroVilla"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fa-brands fa-youtube"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title={t('navBar.tooltipFollowMeInstagram')}
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://www.instagram.com/josuecastrovilla/"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="tiktok-tooltip"
          title={t('navBar.tooltipFollowMeTikTok')}
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://www.tiktok.com/@josuecastrov11"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-tiktok"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="facebook-tooltip"
          title={t('navBar.tooltipFollowMeFacebook')}
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://www.facebook.com/josuecastrovillalobos/"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
