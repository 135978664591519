import * as React from "react";
import { useTranslation } from "react-i18next";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { makeStyles } from '@material-ui/core/styles';
import i18n from "../../I18N";

import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle";

const useStyles = makeStyles(styles);

export default function LanguageToggle() {
  const classes = useStyles();
  const [language, setLanguage] = React.useState("es");
  const { t } = useTranslation();

  const handleLanguage = (event, newLanguage) => {
    if (newLanguage) {
      setLanguage(newLanguage);
      i18n.changeLanguage(newLanguage);
    }
  };

  return (
    <ToggleButtonGroup
      value={language}
      exclusive
      onChange={handleLanguage}
      aria-label="Language"
    >
      <ToggleButton
        value="es"
        aria-label={t('navBar.spanish')}
        className={classes.toggleButton}
      >
        {t('navBar.spanish')}
      </ToggleButton>
      <ToggleButton
        value="en"
        aria-label={t('navBar.english')}
        className={classes.toggleButton}
      >
        {t('navBar.english')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
