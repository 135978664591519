import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";

import styles from "../../assets/jss/material-kit-react/components/footerStyle";

export default function Footer(props) {
  const { whiteFont, position } = props;
  const useStyles = makeStyles(styles(position));
  const classes = useStyles();
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.right}>
          Josue Castro V.
          &copy;
          {1900 + new Date().getYear()} - {1900 + new Date().getYear() + 1}
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
