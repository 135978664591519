import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CardCustom from "./Card/CardCustom";

import styles from "../../assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

export default function TravelVideosSection() {
  const { t } = useTranslation();
  const useStyles = makeStyles(styles({ paddingSection: "0 0 0 0" }));
  const classes = useStyles();

  const cards = [
    {
      image: (
        <div className="ratio ratio-16x9">
          <iframe src="https://www.youtube.com/embed/ud_BU67dq8o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
      ),
      title: t("travelVideoSection.argentinaV2Title"),
      useMediaButtons: false,
      url: ''
    },
    {
      image: (
        <div className="ratio ratio-16x9">
          <iframe src="https://www.youtube.com/embed/dXNmOFn0Pm4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
      ),
      title: t("travelVideoSection.argentinaTitle"),
      useMediaButtons: false,
      url: ''
    },
    {
      image: (
        <div className="ratio ratio-16x9">
          <iframe src="https://www.youtube.com/embed/bWTq36c8tEc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
      ),
      title: t("travelVideoSection.quebradaGataTitle"),
      useMediaButtons: false,
      url: ''
    },
    {
      image: (
        <div className="ratio ratio-16x9">
          <iframe src="https://www.youtube.com/embed/jcPKxoc3-iA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
      ),
      title: t("travelVideoSection.volcanArenalTitle"),
      useMediaButtons: false,
      url: ''
    },
    {
      image: (
        <div className="ratio ratio-16x9">
          <iframe src="https://www.youtube.com/embed/KZ7Vz5HpRbc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
      ),
      title: t("travelVideoSection.cerroCaballitoTitle"),
      useMediaButtons: false,
      url: ''
    },
    {
      image: (
        <div className="ratio ratio-16x9">
          <iframe src="https://www.youtube.com/embed/RJYcPzLkHUg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
      ),
      title: t("travelVideoSection.eolicasTilaranTitle"),
      useMediaButtons: false,
      url: ''
    }
  ];

  return (
    <div className={classes.section}>
    <h2 className={classes.title}>{t("travelVideoSection.title")}</h2>
      <div>
        <GridContainer>
          {cards.map((card, index) => (
            <GridItem xs={12} sm={12} md={4} key={index}>
              <CardCustom card={card} />
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </div>
  );
}
