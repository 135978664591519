// nodejs library that concatenates classes
import classNames from "classnames";
import { useTranslation } from "react-i18next";
// @material-ui/core components
import { makeStyles } from "@material-ui/styles";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

// core components
import CustomHeader from "./Header/CustomHeader.js";
import CustomHeaderLinks from "./Header/CustomHeaderLinks";
import Footer from "./Footer/Footer.js";
import Parallax from "./Parallax/Parallax";
import GridContainer from "./Grid/GridContainer.js";
import GridItem from "./Grid/GridItem.js";

// Utils functions
import { checkMobileAndLandscape } from "../utils/Functions";

// Sections for this page
import MainDescriptionSection from "./Sections/MainDescriptionSection.js";

import styles from "../assets/jss/material-kit-react/views/landingPage.js";

import AcampadaChaparral from "../assets/img/places/AcampadaChaparral.jpg";
import BahiaBallenaUvita1 from "../assets/img/places/BahiaBallenaUvita1.jpg";
import CerroTinajitas2 from "../assets/img/places/CerroTinajitas2.jpg";
import Puntarenas from "../assets/img/places/Puntarenas.jpg";
import PlayaAvellanas2 from "../assets/img/places/PlayaAvellanas2.jpg";
import PlayaCocalito from "../assets/img/places/PlayaCocalito.jpg";
import PlayaMalPais from "../assets/img/places/PlayaMalPais.jpg";
import VolcanPoas1 from "../assets/img/places/VolcanPoas1.jpg";
import VolcanPoas2 from "../assets/img/places/VolcanPoas2.jpg";
import VolcanArenal from "../assets/img/places/VolcanArenal.jpg";

import SanatorioDuran from "../assets/img/places/SanatorioDuran.jpg";
import GreciaParque from "../assets/img/places/GreciaParque.jpg";
import ParqueZarceroIluminado from "../assets/img/places/ParqueZarceroIluminado.jpg";

const useStyles = makeStyles(styles);

export default function PortfolioPage(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { ...rest } = props;
  const isMobileAndLandscape = checkMobileAndLandscape();

  const itemData = [
    {
      img: AcampadaChaparral,
      title: "Acampada bajo un cielo estrellado",
      rows: 2,
      cols: 2
    },
    {
      img: BahiaBallenaUvita1,
      title: "Parque Nacional Marino Ballena"
    },
    {
      img: CerroTinajitas2,
      title: "Cerro Tinajitas"
    },
    {
      img: Puntarenas,
      title: "Puntarenas",
      cols: 2
    },
    {
      img: PlayaAvellanas2,
      title: "Playa Avellanas",
      cols: 2
    },
    {
      img: PlayaCocalito,
      title: "Playa Cocalito",
      rows: 2,
      cols: 2
    },
    {
      img: PlayaMalPais,
      title: "Playa Mal Pais"
    },
    {
      img: VolcanPoas1,
      title: "Volcaán Poas"
    },
    {
      img: VolcanPoas2,
      title: "Volcán Poas",
      rows: 2,
      cols: 2
    },
    {
      img: SanatorioDuran,
      title: "Sanatorio Durán"
    },
    {
      img: GreciaParque,
      title: "Parque de Grecia"
    },
    {
      img: VolcanArenal,
      title: "Volcan Arenal",
      cols: 2
    },
    {
      img: ParqueZarceroIluminado,
      title: "Parque de Zarcero Iluminado",
      rows: 2,
      cols: 4
    }
  ];

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <>
      <CustomHeader
        color="transparent"
        rightLinks={<CustomHeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: isMobileAndLandscape ? 10 : 50,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        filter
        transforming
        image={require("../assets/img/places/QuebradaGata1.jpg")}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>{t('portfolioPage.title')}</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <MainDescriptionSection
            description={t('portfolioPage.miPresentationText1')}
            paddingBottom="0"
          />

          <MainDescriptionSection
            description={t('portfolioPage.miPresentationText2')}
            paddingBottom="0"
          />

          <MainDescriptionSection
            description={t('portfolioPage.miPresentationText3')}
          />

          <Grid container className={classes.cardsContainer}>
            <Grid
              container
              direction="row"
              gap={2}
              columnGap={2}
              className={classes.cardColumn}
            >
              <ImageList
                variant="quilted"
                cols={4}
                rowHeight={121}
              >
                {itemData.map((item) => (
                  <ImageListItem
                    key={item.img}
                    cols={item.cols || 1}
                    rows={item.rows || 1}
                  >
                    {item.img && (
                      <img
                        {...srcset(item.img, 121, item.rows, item.cols)}
                        alt={item.title}
                        loading="lazy"
                      />
                    )}
                    {item.content}
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
          </Grid>

          <MainDescriptionSection
            description={t('portfolioPage.miPresentationText4')}
            paddingBottom="0"
          />

          <MainDescriptionSection description={t('portfolioPage.miPresentationText5')} />
        </div>
      </div>

      <Parallax
        filter
        uping
        image={require("../assets/img/places/BahiaBallenaUvita2.jpg")}
        height="20.8vh"
        style={{ top: "-50px" }}
      >
        <div className={classes.container}>
          <Footer />
        </div>
      </Parallax>
    </>
  );
}
