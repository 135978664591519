import { container, title } from "../../material-kit-react";
import imagesStyle from "../../../../assets/jss/material-kit-react/imagesStyles.js";

import tooltip from "../../../../assets/jss/material-kit-react/tooltipsStyle";

const landingPageStyle = (theme) => ({
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
  },
  notFoundContainer: {
    zIndex: "12",
    color: "#FFFFFF",
    position: "relative",
    height: "55%",
    alignSelf: "end",
    ...container,
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "20px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  ...imagesStyle,
  itemGrid: {
    maxWidth: "100%"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  navLink: {
    color: "inherit",
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    fontFamily: "Orkney",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    padding: "0.9375rem",
    "&:hover,&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)"
    }
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px",
  },
  mainButton: {
    color: "inherit",
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    fontFamily: "Orkney",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    boxShadow: "none",
    "&:hover,&:focus": {
      boxShadow:
        "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)",
      color: "inherit",
      backgroundColor: "#456673"
    },
    backgroundColor: "#456673"
  },
  cardColumn: {
    justifyContent: 'center'
  },
  cardsContainer: {
    paddingBottom: '30px',
  },
  contactFont: {
    color: '#AAAAAA !important',
    input: {
      color: '#AAAAAA !important'
    }
  },
  ...tooltip
});

export default landingPageStyle;
