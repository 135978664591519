import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { useTranslation } from 'react-i18next';
import MainDescriptionSection from "../Sections/MainDescriptionSection.js";

// core components
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import CardCustom from "./Card/CardCustom";

import CarroToyotaCorolla from "../../assets/img/places/CarroToyotaCorolla.jpg";
import MotoYamahaMono from "../../assets/img/places/MotoYamahaMono.jpg";
import CanchaDelRosario from "../../assets/img/places/CanchaDelRosario.jpg";
import LandRoverEvent from "../../assets/img/places/LandRoverEvent.jpg";

import CondominioCocoVideo from "../../assets/videos/places/CondominioCocoVideo.mp4";

import styles from "../../assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

export default function ProductSection() {
  const { t } = useTranslation();
  const useStyles = makeStyles(styles({ paddingSection: "0 0 0 0" }));
  const classes = useStyles();

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgFluid
  );

  const cards = [
    {
      image: <img src={CarroToyotaCorolla} alt="Toyota Corolla" className={imageClasses} />,
      title: t("productsSection.toyotaCorollaTitle"),
      description: t("productsSection.toyotaCorollaDescription"),
      useMediaButtons: false,
      url: ''
    },
    {
      image: <img src={MotoYamahaMono} alt="Yamaha Mono" className={imageClasses} />,
      title: t("productsSection.yamahaMonoTitle"),
      description: t("productsSection.yamahaMonoDescription"),
      useMediaButtons: false,
      url: ''
    },
    {
      image: <img src={CanchaDelRosario} alt="Cancha del Rosario" className={imageClasses} />,
      title: t("productsSection.canchaRosarioTitle"),
      description: t("productsSection.canchaRosarioDescription"),
      useMediaButtons: false,
      url: ''
    },
    {
      image: <img src={LandRoverEvent} alt="Evento de Land Rover" className={imageClasses} />,
      title: t("productsSection.landRoverEventTitle"),
      description: t("productsSection.landRoverEventDescription"),
      useMediaButtons: false,
      url: ''
    },
    {
      image: (
        <video autoPlay loop muted alt={t("productsSection.pacificCondoTitle")} className={imageClasses}>
        {/* <video autoPlay loop muted alt={t("productsSection.pacificCondoTitle")} className={imageClasses}> */}
          <source src={CondominioCocoVideo} type="video/mp4" />
        </video>
      ),
      // image: <img src={CondominioCoco1} alt="Grabacion en Pacifico Paradise Condominio" className={imageClasses} />,
      title: t("productsSection.pacificCondoTitle"),
      description: t("productsSection.pacificCondoDescription"),
      useMediaButtons: false,
      url: ''
    }
  ];

  return (
    <div className={classes.section}>
    <h2 className={classes.title}>{t("productsSection.title")}</h2>    
    <MainDescriptionSection description={t("productsSection.description")} />
      <div>
        <GridContainer>
          {cards.map((card, index) => (
            <GridItem xs={12} sm={12} md={4} key={index}>
              <CardCustom card={card} />
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </div>
  );
}
