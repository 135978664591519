import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// @material-ui/icons

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CardCustom from "./Card/CardCustom";

import styles from "../../assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import BusinessPhotography from "../../assets/img/places/BusinessPhotography.jpg";
import HouseOfDirt from "../../assets/img/places/HouseOfDirt.jpg";
import LotesPhotography from "../../assets/img/places/LotesPhotography.jpg";
import CondominioCoco2 from "../../assets/img/places/CondominioCoco2.jpg";
import QuebradaGata from "../../assets/img/places/QuebradaGata.jpg";

const useStyles = makeStyles(styles());

export default function ServicesSection() {
  const { t } = useTranslation();
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgFluid
  );

  const cards = [
    {
      image: <img src={BusinessPhotography} alt={t("servicesSection.recordYourBusinessTitle")} className={imageClasses} />,
      title: t("servicesSection.recordYourBusinessTitle"),
      description: t("servicesSection.recordYourBusinessDescription"),
      useMediaButtons: false
    },
    {
      image: <img src={HouseOfDirt} alt={t("servicesSection.extremeSportsTitle")} className={imageClasses} />,
      title: t("servicesSection.extremeSportsTitle"),
      description: t("servicesSection.extremeSportsDescription"),
      useMediaButtons: false
    },
    {
      image: <img src={CondominioCoco2} alt={t("servicesSection.condosTitle")} className={imageClasses} />,
      title: t("servicesSection.condosTitle"),
      description: t("servicesSection.condosDescription"),
      useMediaButtons: false
    },
    {
      image: <img src={QuebradaGata} alt={t("servicesSection.hotelTitle")} className={imageClasses} />,
      title: t("servicesSection.hotelTitle"),
      description: t("servicesSection.hotelDescription"),
      useMediaButtons: false
    },
    {  
      image: <img src={LotesPhotography} alt={t("servicesSection.lotesFotographyTitle")} className={imageClasses} />,
      title: t("servicesSection.lotesFotographyTitle"),
      description: t("servicesSection.lotesFotographyDescription"),
      useMediaButtons: false
    }
  ];

  return (
    <div className={classes.section}>
      <h2 className={classes.title}>{t("servicesSection.title")}</h2>
      <div>
        <GridContainer>
          {cards.map((card, index) => (
            <GridItem xs={12} sm={12} md={4} key={index}>
              <CardCustom card={card} />
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </div>
  );
}
