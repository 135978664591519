import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import MainPage from './components/MainPage';
import ContactPage from './components/ContactPage';
import InformationPage from './components/InformationPage';
import PortfolioPage from './components/PortfolioPage';
import NotFoundPage from './components/NotFoundPage';

import "./assets/scss/material-kit-react.scss?v=1.10.0";
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';

const AppContainer = styled.div`
  height: 0;
`;

function App() {
  return (
    <AppContainer>
      <Router>
        <Routes>
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/information" element={<InformationPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/" element={<MainPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </AppContainer>
  );
}

export default App;
