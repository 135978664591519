// nodejs library that concatenates classes
import classNames from "classnames";
import { useTranslation } from "react-i18next";
// @material-ui/core components
import { makeStyles } from "@material-ui/styles";
import Grid from '@mui/material/Grid';

// core components
import CustomHeader from "./Header/CustomHeader.js";
import CustomHeaderLinks from "./Header/CustomHeaderLinks";
import Footer from "./Footer/Footer.js";
import Parallax from "./Parallax/Parallax";
import GridContainer from "./Grid/GridContainer.js";
import GridItem from "./Grid/GridItem.js";
import CardCustom from "./Sections/Card/CardCustom";

// Utils functions
import { checkMobileAndLandscape } from "../utils/Functions";

// Sections for this page
import MainDescriptionSection from "./Sections/MainDescriptionSection.js";

import styles from "../assets/jss/material-kit-react/views/landingPage.js";

import CamaraGimbal from "../assets/img/equipment/CamaraGimbal.jpg";
import DroneDJIMavic3ProRCPro from "../assets/img/equipment/DroneDJIMavic3ProRCPro.jpg";
import Chimera7 from "../assets/img/equipment/DroneChimera7.jpg";
import SourceOne5 from "../assets/img/equipment/DroneSourceOne5.jpg";
import Cinelog35 from "../assets/img/equipment/DroneCinelog35.jpg";
import Cinelog25 from "../assets/img/equipment/DroneCinelog25.jpg";

const useStyles = makeStyles(styles);

export default function InformationPage(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgFluid
  );
  const { ...rest } = props;
  const isMobileAndLandscape = checkMobileAndLandscape();

  const cards = [
    {
      title: t('informationPage.camaraGimbalTitle'),
      image: (
        <img src={CamaraGimbal} alt="Camara A6400 Gimbal Zhiyun Weebill 2" className={imageClasses} />
      ),
      description: t('informationPage.camaraGimbalDescription')
    },
    {
      title: t('informationPage.djiMavic3ProRCProTitle'),
      image: (
        <img src={DroneDJIMavic3ProRCPro} alt="Drone DJI Mavic 3 Pro RC Pro" className={imageClasses} />
      ),
      description: t('informationPage.djiMavic3ProRCProDescription')
    },
    {
      title: t('informationPage.chimera7Title'),
      image: (
        <img src={Chimera7} alt="Drone Chimera 7" className={imageClasses} />
      ),
      description: t('informationPage.chimera7Description'),
    },
    {
      title: t('informationPage.sourceOne5Title'),
      image: (
        <img src={SourceOne5} alt="Drone Source One 5" className={imageClasses} />
      ),
      description: t('informationPage.sourceOne5Description')
    },
    {
      title: t('informationPage.cinelog35Title'),
      image: (
        <img src={Cinelog35} alt="Drone Cinelog 35" className={imageClasses} />
      ),
      description: t('informationPage.cinelog35Description')
    },
    {
      title: t('informationPage.cinelog25Title'),
      image: (
        <img src={Cinelog25} alt="Drone Cinelog 25" className={imageClasses} />
      ),
      description: t('informationPage.cinelog25Description')
    }
  ];

  return (
    <>
      <CustomHeader
        color="transparent"
        rightLinks={<CustomHeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: isMobileAndLandscape ? 10 : 50,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter transforming image={require("../assets/img/places/CerroTinajitas1.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>{t('informationPage.title')}</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <MainDescriptionSection
            description={t('informationPage.description')}
          />
          <Grid container className={classes.cardsContainer}>
            <Grid container direction='row' gap={2} columnGap={2} className={classes.cardColumn}>
              {cards.map((card, index) => (
                <GridItem xs={12} sm={12} md={4} key={index}>
                  <CardCustom card={card} />
                </GridItem>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
      <Parallax filter uping image={require("../assets/img/places/BahiaBallenaUvita2.jpg")} height="20.8vh" style={{ top: "-50px" }}>
        <div className={classes.container}>        
          <Footer />
        </div>
      </Parallax>
    </>
  );
}
