import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// core components
import CustomHeader from "./Header/CustomHeader.js";
import Footer from "./Footer/Footer.js";
import GridContainer from "./Grid/GridContainer.js";
import GridItem from "./Grid/GridItem.js";
import Button from "./CustomButtons/Button";
import CustomHeaderLinks from "./Header/CustomHeaderLinks";
import Parallax from "./Parallax/Parallax";

import styles from "../assets/jss/material-kit-react/views/landingPage.js";

// Utils functions
import { checkMobileAndLandscape } from "../utils/Functions";

const useStyles = makeStyles(styles);

export default function NotFoundPage(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { ...rest } = props;

  const [isMobileAndLandscape, setIsMobileAndLandscape] = useState(
    checkMobileAndLandscape()
  );

  function detect() {
    setIsMobileAndLandscape(checkMobileAndLandscape());
  }

  useEffect(() => {
    window.addEventListener("resize", detect);
  }, []);

  const BotonPaginaInicio = () => (
    <Link to="/" className={classes.mainButton}>
      <Button
        className={classes.mainButton}
        size="lg"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('notFoundPage.initialPageButton')}
      </Button>
    </Link>
  );

  return (
    <>
      <CustomHeader
        color="transparent"
        rightLinks={<CustomHeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: isMobileAndLandscape ? 10 : 50,
          color: "white"
        }}
        {...rest}
      />
      <Parallax
        filter
        transforming
        image={require("../assets/img/places/ParqueSarchi2.jpg")}
        height='100vh'
        maxHeight='none'
      >
        <div className={classes.notFoundContainer}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              {!isMobileAndLandscape && (
                <>
                  <h1>
                    {t('notFoundPage.code')}
                  </h1>
                  <h4>
                    {t('notFoundPage.notFoundPage')}
                  </h4>
                  <br />
                  <BotonPaginaInicio />
                </>
              )}
              {isMobileAndLandscape && (
                <>
                  <h1>
                    {t('notFoundPage.code')}
                  </h1>
                  <h5>
                    {t('notFoundPage.notFoundPage')}
                  </h5>
                  <br />
                  <BotonPaginaInicio />
                </>
              )}
            </GridItem>
          </GridContainer>
          <Footer position="absolute" />
        </div>
      </Parallax>
    </>
  );
}
