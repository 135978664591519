import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/styles";

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

import styles from "../../assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

export default function MainDescriptionSection(props) {
  const { description, title, paddingBottom } = props;
  const useStyles = makeStyles(styles(paddingBottom));
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          { title && <h2 className={classes.title}>{title}</h2>}
          <h5 className={classes.description}>
            {description}
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
