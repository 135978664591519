import React, { createRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
import Recaptcha from 'react-google-invisible-recaptcha';
import axios from 'axios';

// core components
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import CustomInput from "../CustomInput/CustomInput";
import Button from "../CustomButtons/Button";

import styles from "../../assets/jss/material-kit-react/views/landingPage.js";

function isEverythingFilled(data) {
  let isEveryOk = true;
  const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

  if (data.name.length <= 0) {
    isEveryOk = false;
  }
  
  if (data.email.length <= 0) {
    isEveryOk = false;
  }

  if (!emailRegex.test(data.email)) {
    isEveryOk = false;
  }

  if (data.message.length <= 0) {
    isEveryOk = false;
  }

  return isEveryOk;
}

class ContactSection extends React.Component {
  constructor( props ) {
    super(props);
    this.state = {};
    this.onResolved = this.onResolved.bind(this);
    this.sendContactInformation = this.sendContactInformation.bind(this);
    this.recaptcha = createRef(null);
  }

  onResolved = async (token) => {
    const params = {
      formData: this.state,
      token
    };
    const status = await axios.post('http://localhost:8080/reviewTokenAndSendEmail', params);
    // const status = await axios.post('https://josue-castro-website-server.herokuapp.com/reviewTokenAndSendEmail', params);

    if (status) {
      this.props.setInfoMessage(this.props.t("contactSection.requestSuccess"));
    } else {
      this.props.setInfoMessage(this.props.t("contactSection.invalidToken"));
    }
  }

  sendContactInformation = (event) => {
    event.preventDefault();
    const newData = {
      name: event.target.name?.value.trim(),
      email: event.target.email?.value.trim(),
      message: event.target.message?.value.trim(),
      fromSource: 'josuecv.com',
      replyTo: 'josuecastrovilla@gmail.com',
      worked: false,
      time: Date.now()
    };
    
    if (isEverythingFilled(newData)) {
      this.setState(newData);
      this.recaptcha.current.execute();
    } else {
      this.props.setErrorMessage(this.props.t("contactSection.invalidDataOrEmpty"));
      this.recaptcha.current.reset();
    }
  }

  render() {
    const { classes, fontClass, fontColor, t } = this.props;
    const descriptionClasses = classNames(classes.description, fontClass);
    const titleClasses = classNames(classes.title, fontClass);
    const inputClasses = classNames(fontClass);

    return (
      <div className={classes.section} style={{ marginTop: "50px" }}>
        <Recaptcha
          ref={this.recaptcha}
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          onResolved={this.onResolved}
        />
        <GridContainer justifyContent="center">
          <GridItem cs={12} sm={12} md={8}>
            <h2 className={titleClasses}>
              {t("contactSection.letsWork")}
            </h2>
            <h4 className={descriptionClasses}>
              {t("contactSection.yourPreferenceBetterSatisfation")}
              <br/>
              {t("contactSection.yourRequestWillBeProcessAndWeWillContactYou")}
            </h4>
            <form id="requestForm" onSubmit={this.sendContactInformation}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={t("contactSection.name")}
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    className={inputClasses}
                    white={fontColor}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText={t("contactSection.email")}
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    className={inputClasses}
                    white={fontColor}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText={t("contactSection.message")}
                    id="message"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.textArea,
                    }}
                    className={inputClasses}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                    }}
                    white={fontColor}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Button
                    color="primary"
                    type="submit"
                    className={classes.mainButton}
                  >
                    {t("contactSection.sendMessage")}
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ContactSection.propTypes = {
  sendData: PropTypes.func,
  setErrorMessage: PropTypes.func,
  fontColor: PropTypes.string
};

export default withStyles(styles)(ContactSection);
