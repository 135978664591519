import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// core components
import CustomHeader from "./Header/CustomHeader.js";
import Footer from "./Footer/Footer.js";
import GridContainer from "./Grid/GridContainer.js";
import GridItem from "./Grid/GridItem.js";
import Button from "./CustomButtons/Button";
import CustomHeaderLinks from "./Header/CustomHeaderLinks";
import Parallax from "./Parallax/Parallax";

import styles from "../assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import MainDescriptionSection from "./Sections/MainDescriptionSection.js";
import ServicesSection from "./Sections/ServicesSection.js";
import ProductSection from "./Sections/ProductSection.js";
import TravelVideosSection from "./Sections/TravelVideosSection.js";
import ContactSection from "./Sections/ContactSection.js";

// Utils functions
import { checkMobileAndLandscape } from "../utils/Functions";

const useStyles = makeStyles(styles);

export default function MainPage(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const classes = useStyles();
  const { ...rest } = props;
  const [isMobileAndLandscape, setIsMobileAndLandscape] = useState(
    checkMobileAndLandscape()
  );
  
  function detect() {
    setIsMobileAndLandscape(checkMobileAndLandscape());
  }

  useEffect(() => {
    window.addEventListener("resize", detect);
  }, []);

  const openSnackbar = () => {
    setOpen(true);
  };

  const closeSnackbar = () => {
    setOpen(false);
  };

  const setInfoMessage = (message) => {
    setMessage(message);
    openSnackbar();
  };

  const QueVisitarButton = () => (
    <Link to="/information" className={classes.mainButton}>
      <Button
        className={classes.mainButton}
        size="lg"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('mainPage.mainContentButton')}
      </Button>
    </Link>
  );

  return (
    <>
      <CustomHeader
        color="transparent"
        rightLinks={<CustomHeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: isMobileAndLandscape ? 10 : 50,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        filter
        transforming
        image={require("../assets/img/places/CerroPelonDJI.jpg")}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              {!isMobileAndLandscape && (
                <>
                  <h4>
                    {t('mainPage.mainService')}
                  </h4>
                  <br />
                  <QueVisitarButton />
                </>
              )}
              {isMobileAndLandscape && (
                <>
                  <h5>
                    {t('mainPage.mainService')}
                  </h5>
                  <br />
                  <QueVisitarButton />
                </>
              )}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <MainDescriptionSection />
          <ServicesSection />
          <ProductSection />
          <TravelVideosSection />
        </div>
      </div>
      <Parallax
        filter
        uping
        image={require("../assets/img/places/BahiaBallenaUvita2.jpg")}
        height="170vh"
        style={{ top: "-100px" }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <ContactSection
                fontColor="#FFFFFF"
                setInfoMessage={setInfoMessage}
                t={t}
              />
            </GridItem>
          </GridContainer>
          <Footer />
        </div>
      </Parallax>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        message={message}
      />
    </>
  );
}
